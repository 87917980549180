<template>
  <div class="MobileHeader" :class="{
    'MobileHeader-small': smallHeader,
    'MobileHeader-isMobileMenu': smallHeader && isMobileMenu,
  }">
    <div class="header">
      <img class="logo" @click="toHome" src="./image/logo-czy3d.svg" alt="" />
      <div class="btn-menu-wap-list">
        <div class="name" v-if="user">
          {{ user.username }}
        </div>
        <img v-if="user" @click="toUser" class="btn-menu-wap" src="./image/btn-avata-wap.svg" alt="" />
        <img v-if="!isMobileMenu" class="btn-menu-wap" src="./image/btn-menu-wap.svg" alt="" @click="openMenu(true)" />
        <img v-if="isMobileMenu" class="btn-menu-wap" src="./image/btn-close-wap.svg" alt="" @click="openMenu(false)" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import { getUserInfo, removeUserInfo } from "@/static/js/userInfo";
export default {
  name: "MobileHeader",
  data() {
    return {
      user: getUserInfo(),
    };
  },
  computed: {
    ...mapState(["isMobileMenu", "userInfo"]),
    smallHeader() {
      return this.$route.meta.smallHeader;
    },
  },
  watch: {
    userInfo(v) {
      if (v) {
        this.user = v;
      } else {
        this.user = getUserInfo();
      }
    },
  },
  mounted() {
    console.log(this.user);
  },
  methods: {
    ...mapMutations(["setIsMobileMenu"]),
    openMenu(isOpen) {
      this.setIsMobileMenu(isOpen);
    },
    toHome() {
      this.$router.push("/");
    },
    toUser() {
      this.$router.push("/MobileUser");
      this.setIsMobileMenu(false);
    },
  },
};
</script>

<style lang="scss">
.mobile-home-drawer-menu {
  .el-drawer__header {
    display: none;
  }

  .el-drawer__body {
    padding: 0;
    background-color: #4e26de;
    padding: 0 31px;
    // padding-top: calc(13px + 50px + 30px);
    padding-top: 93px;

    .login-out {
      .user-name {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        line-height: 48px;
        text-align: left;
        color: #ffffff;
      }

      .logout-btn {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        line-height: 48px;
        text-align: left;
        color: #ff4500;
      }
    }

    .login-btn {
      display: flex;
      align-items: center;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 600;
      line-height: 48px;
      text-align: left;
      color: #ffffff;

      .vertical-line {
        margin: 0 8px;
      }
    }

    .menu-list {
      .menu-item {
        font-family: PingFang SC;
        font-size: 20px;
        font-weight: 600;
        line-height: 48px;
        text-align: left;
        color: #ffffff;
      }
    }

    .line {
      height: 1px;
      width: 100%;
      background-color: #ffffff;
      margin: 23px 0;
    }
  }
}
</style>
<style scoped lang="scss">
.MobileHeader {
  position: fixed;
  top: 13px;
  left: 8px;
  z-index: 9999;
  width: calc(100vw - 16px);
  height: 50px;
  border-radius: 46px;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.2);
  border: 1.4px solid rgba(255, 255, 255, 0.3);

  &.black {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .logo {
    position: absolute;
    left: 23px;
    top: 11px;
    width: 146px;
    height: 30px;
  }

  .btn-menu-wap-list {
    position: absolute;
    right: 9px;
    top: 9px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .name {
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: 400;
      line-height: 16.8px;
      text-align: right;
      max-width: 70px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #ffffff;
      margin-right: 7px;
    }

    .btn-menu-wap {
      width: 32px;
      height: 32px;

      &+.btn-menu-wap {
        margin-left: 7px;
      }
    }
  }

  &-small {
    .logo {
      display: none;
    }

    width: 32px;
    height: 32px;
    left: auto;
    right: 8px;
    overflow: hidden;
    border-radius: 0;
    border: none;
    background-color: transparent;
    backdrop-filter: none;

    .btn-menu-wap-list {
      right: 0px;
      top: 0px;
    }
  }

  &-isMobileMenu {
    left: 8px;
    right: auto;
    width: calc(100vw - 16px);
    height: 50px;
    border-radius: 46px;
    backdrop-filter: blur(16px) saturate(180%);
    -webkit-backdrop-filter: blur(16px) saturate(180%);
    background-color: rgba(255, 255, 255, 0.2);
    border: 1.4px solid rgba(255, 255, 255, 0.3);

    .logo {
      display: block;
    }

    .btn-menu-wap-list {
      right: 9px;
      top: 9px;
    }
  }
}
</style>
