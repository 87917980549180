import request from "@/static/js/request";

/*流量分析*/
export function getAppList() {
  return request({
    url: "/tenant/app/list",
    method: "get",
    ignoreLoading: true,
  });
}
