<template>
  <div>
    <component v-for="item in this.activeActivities" :key="item.key" :is="item.component" :style="getStyle(item)"
               class="pointer"
               @close="doClose(item)"
               @click.native="item.go"
    ></component>
  </div>
</template>

<script>
//弹窗判定有效时间 1 day
import { checkIsMobile } from "@/static/utils/utils";

const expireTime = 86400000;
const CLOSED_ACTIVITIES = 'POPUP_MANAGER_CLOSED_ACTIVITIES';
// const isMobile = checkIsMobile();
export default {
  name: "ActivitiesPopupManager",
  props: {
    activities: Array
  },
  data() {
    return {
      closed: [],
      activeActivities: [],
    }
  },
  methods: {
    doClose(data) {
      this.closed = this.closed.filter(item => item.key !== data.key);
      this.closed.push({
        key: data.key,
        date: Date.now()
      })
      this.updateActiveList();
      this.saveClosedToStorage();
    },
    getStyle(item) {
      return {
        position: 'fixed',
        ...item.position.reduce((acc, cur) => {
          // acc[cur] = isMobile ? '5px' : '20px';
          acc[cur] = 0;
          return acc;
        }, {}),
        zIndex: 10000,
      }
    },
    updateActiveList() {
      this.activeActivities = this.activities.filter(item => !this.closed.find(itm => {
        return item.key === itm.key && itm.date + expireTime >= Date.now()
      }))
    },
    saveClosedToStorage() {
      localStorage.setItem(CLOSED_ACTIVITIES, JSON.stringify(this.closed));
    },
    updateClosedFromStorage() {
      try {
        this.closed = localStorage.getItem(CLOSED_ACTIVITIES) ? JSON.parse(localStorage.getItem(CLOSED_ACTIVITIES)) : [];
      } catch (e) {
        this.closed = []
      }
    },
    onStorage(ev) {
      if (ev.key === CLOSED_ACTIVITIES || !ev.key) {
        this.updateClosedFromStorage();
        this.updateActiveList();
      }
    }
  },
  created() {
    window.addEventListener('storage', this.onStorage)
    this.updateClosedFromStorage();
    this.updateActiveList();
  },
  mounted() {
  },
  beforeDestroy() {
    window.removeEventListener('storage', this.onStorage)
  }
}
</script>

<style scoped lang="scss">
</style>
