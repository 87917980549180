export default {
  "n1": "为 工 业 而 生",
  "n2": "让数字化更简单",
  "n3": "杭州炽橙数字科技有限公司（炽橙科技）成立于2015年，由中国工程院谭建荣院士技术领衔，长期聚焦工业数据智能与人机协同交互技术，在自主可控的工业图形引擎与数字孪生领域国内领先。业务范围覆盖智能制造、军民融合、职业教育，先后服务 4大核电集团、中国船舶集团等数十家央国企，博世中国、吉利汽车、西奥电梯等 1200 多家跨国与民营制造企业，以及浙江大学、合肥工业大学等 100多家科研院所。",
  "n4": "炽橙科技拥有国家高新技术企业、省级专精特新中小企业、2024工业智能数字化转型影响力企业等荣誉，建有省级高新技术企业研究开发中心，并与浙江大学共建“浙大-炽橙先进智能数字孪生联合研究中心”。“国产工业元宇宙智能交互底座”获得2023年浙江省先进（未来）技术创新成果，“面向先进制造业的数字孪生工厂” 项目入选 2024年“新质生产力看浙江”首批典型案例，“双环传动未来1号数字工厂”项目入选 2024 数商典型应用场景“乘数榜”。",
  "n5": "最新消息",
  "n6": "炽橙合作伙伴计划",
  "n7": "共建开放、合作、共赢的新质生产力生态",
  "n8": "成为合作伙伴",
  "n9": "商机共享",
  "n10": "面向各行业价值场景",
  "n11": "在优势领域商机共享",
  "n12": "方案联合",
  "n13": "整合双方产品服务",
  "n14": "联合拓展解决方案",
  "n15": "产品分销",
  "n16": "授权区域内生态分销",
  "n17": "技术与服务合作共赢",
  "n18": "使命",
  "n19": "数智新引擎，驱动生产力",
  "n20": "愿景",
  "n21": "成为工业智能交互领域的引领者",
  "n22": "价值观",
  "n23": "真诚务实 卓越创新",
  "n24": "拼搏进取 共赢回报",
  "n25": "团队精神",
  "n26": "热爱而卓越，创新而不凡",
  "n27": "#3D创智大赛",
  "n28": "更新日期",
  "n29": "微信扫码分享文章",
  "n30": "搜索",
  "n31": "所有",
  "n32": "精选应用",
  "n33": "所有应用",
  "n34": "最新",
  "n35": "最热",
  "n36": "合肥工业大学 x 炽橙科技<br/>“炽橙杯” 3D 创智大赛",
  "n37": "和发圈一样简单<br/>超真云手册 AI 生成",
  "n38": "XIAOMI SU7<br/  >超真云手册"
}