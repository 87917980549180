import request from "@/static/js/request";

/*还需要加最新或最热*/
export function paramsList(data) {
  return request({
    url: "/search/params",
    method: "get",
    data: data,
  });
}

/*联系我们*/
export function touchUsSubmit(data) {
  return request({
    url: "/contactus/save",
    method: "post",
    data: data,
  });
}
/*返回oss链接*/
export function sysConfigByKey(configKey) {
  return request({
    url: `/sys/config/configKey/${configKey}`,
    method: "get",
    
  });
}