const getters = {
  loading: (state) => state.loading,
  userInfo: (state) => state.userInfo,
  loginRegistDialogVisible: (state) => state.loginRegistDialogVisible,
  scroolArrowShow: (state) => state.scrollArrowShow,
  registrationWay: (state) => state.registrationWay, // 注册方式
  spaceIndex: (state) => state.spaceIndex,
  classIndex: (state) => state.classIndex,
  visibleIndex: (state) => state.visibleIndex,
  orderIndex: (state) => state.orderIndex,
  filterParams: (state) => state.filterParams,
  filterIndex: (state) => state.filterIndex,
  topOrderInfo: (state) => state.topOrderInfo,
  appList: (state) => state.appList,
  getPageUrl: (state) => state.getPageUrl,
  isNowWin: (state) => state.isNowWin,
};
export default getters;
