/*
 * @Author: your name
 * @Date: 2021-11-24 09:19:06
 * @LastEditTime: 2022-01-24 13:42:53
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \editor_pc\src\static\js\authDecorators.js
 */
import router from "@/router";
import { getUserInfo } from "@/static/js/userInfo";
import store from "@/store";

Function.prototype.before = function (beforefn) {
  var __self = this;
  return function () {
    var pass = beforefn.apply(this, arguments);
    if (pass) {
      return __self.apply(this, arguments);
    }
    return;
  };
};

export function requireAuthBefore(...args) {
  return function (target, key, descriptor) {
    descriptor.value = descriptor.value.before(() => {
      let userInfo = getUserInfo();
      if (userInfo) {
        return true;
      }
      store.commit("changeUserInfo", null);

      let width = document.documentElement.clientWidth;

      store.commit("changeLoginDialogVisible", 7);

      // if (width > 750) {
      //   store.commit("changeLoginDialogVisible", 7);
      // } else {
      //   router.push("/login");
      // }
      try {
        TDAPP.onEvent("唤醒登录弹窗");
      } catch (error) {}
      return false;
    });
  };
}
