import request from "@/static/js/request";


export function queryNotifications(params) {
    return request({
        url: '/notification/list',
        method: 'get',
        params:params,
    })
}

// /mine/space/join
export function queryNotifijoin(data) {
  return request({
      url: '/mine/space/join',
      method: 'post',
      qsRequire: true,  
      data
  })
}

/** 查看空间下的成员 */
export function getSpataiUsers(params) {
    return request({
        url: '/mine/space/users',
        method: 'get',
        params:params
    })
  }
  
  /** 空间协作*/
  export function getSpataiList(params) {
    return request({
        url: '/space/notification/list',
        method: 'get',
        params:params
    })
  }
 /** 空间协作*/
 export function getKnowledgeList() {
    return request({
        url: '/space/notification/knowledgeTransfer/list',
        method: 'get'
    })
}
  
// 通知机制是否存在未读信息
export function check() {
  return request({
    url: "/notification/check",
    method: "get",
  });
}


/** 通知信息列表 */ 
export function sysNotice(params) {
  return request({
    url: "/sysNotice/list",
    method: "get",
    params
  });
}