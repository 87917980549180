<template>
  <transition name="PayFailureBanner">
    <div class="PayFailureBanner" v-if="isShow">
      <div>您有一笔订单还未支付，将于{{ time.minute }}分 {{ time.second }}秒 后失效，应付金额 ¥{{ order.orderAmountTotal }}</div>
      <div class="to-pay" @click="toPay">立即付款</div>
      <div class="to-pay" @click="cancelOrder">取消付款</div>
      <!-- 订单支付选择 -->
      <OrderPayDialog ref="OrderPayDialog" />
    </div>
  </transition>
</template>

<script>
import OrderPayDialog from "./OrderPayDialog.vue";
import { getUserInfo } from "@/static/js/userInfo";
import { mapGetters } from "vuex";
import { getOrderList, cancelAccountOrder, cancelVasOrder, cancelCurrencyOrder, cancelSpaceOrder } from "@/api/pay";
export default {
  name: "PayFailureBanner",
  components: {
    OrderPayDialog,
  },
  data() {
    return {
      order: {},
      timer: null,
      time: {
        minute: "",
        second: "",
      },
      isShow: false,
    };
  },
  mounted() {
    if (getUserInfo()) {
      this.$store.dispatch("GetNewOrder");
    }
  },
  watch: {
    // 监听当前路由
    $route(n) {
      if (n.path === "/overview") {
        this.$store.dispatch("GetNewOrder");
      }
    },
    topOrderInfo(n) {
      if (n) {
        this.order = n;
        this.countDownTime();

      } else {
        this.order = n;
        clearInterval(this.timer);
        this.isShow = false;
      }
    },
  },
  computed: {
    ...mapGetters(["topOrderInfo"]),
  },
  methods: {
    countDownTime() {
      this.timer = setInterval(() => {
        const now = new Date().getTime();
        const endTime = new Date(this.order.createTime).getTime();
        const time = 30 * 60 * 1000 - (now - endTime);
        const minute = Math.floor(time / 1000 / 60);
        const second = Math.floor((time - minute * 60 * 1000) / 1000);
        if (time <= 0) {
          clearInterval(this.timer);
          this.isShow = false;
          return;
        }
        this.isShow = true;
        this.time = {
          minute,
          second,
        };
      }, 1000);
    },
    cancelOrder(order) {

      this.$confirm("确定取消订单吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const cancelAPI = {
            ACCOUNT: cancelAccountOrder,
            VAS: cancelVasOrder,
            CURRENCY: cancelCurrencyOrder,
            SPACE: cancelSpaceOrder,
          }[this.order.orderType];
          cancelAPI({
            orderNo: this.order.orderNo,
          }).then((res) => {
            if (res.code == 200) {
              this.$showMessage("s", "取消订单成功");
              this.getOrderList();
              this.$store.dispatch("GetNewOrder");
            } else {
              this.$showMessage("e", res.message);
            }
          });
        })
        .catch(() => { });
    },
    getOrderList() {
      getOrderList(this.searchParams).then(res => {
        this.orderList = res.data;
      });
    },
    toPay() {
      this.$refs.OrderPayDialog.init(this.order, this.order.orderType);
    },
  },
};
</script>

<style scoped lang="scss">
.PayFailureBanner {
  width: 100%;
  height: 40px;
  background: #e6541b;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  .to-pay {
    margin-left: 20px;
    cursor: pointer;
    text-decoration: underline;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hidden {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.PayFailureBanner-enter-active {
  animation: show ease-in-out 0.3s;
}

.PayFailureBanner-leave-active {
  animation: hidden ease-in-out 0.3s;
}
</style>
