import { _localStorage } from "@/static/js/storage";
import store from "@/store";

import Cookies from "js-cookie/src/js.cookie";



const userKey = "editor_userInfo";

//获取用户信息
export function getUserInfo() {
  const user = _localStorage.get(userKey);

  if (!user) {
    return null;
  }
  /*重新设置 刷新过期时间*/
  saveUserInfo(user);
  return user;
}

export function saveTemplate(id) {
  let num = 2;
  let time = new Date(new Date().getTime() + num * 3600 * 1000);
  Cookies.set("template", id, {
    expires: time,
    path: "/",
    domain: process.env["VUE_APP_domain_LINK"],
    // domain: "192.168.2.76",
    // domain: ".czy3d.com",
    httpOnly: false,
  });
}
export function saveUserInfo(user) {
  // 更新用户信息的时候，如果token不存在，则从cookie中获取
  if (!user.token) {
    user.token = getUserInfo().token;
  }

  _localStorage.set(userKey, user, 2 * 3600 * 1000);
  {
    //设置cookie 子域共享token
    /*/!*两小时过期*!/*/
    let num = 2;
    let time = new Date(new Date().getTime() + num * 3600 * 1000);
    Cookies.set("editor_token", user.token, {
      expires: time,
      path: "/",
      domain: process.env["VUE_APP_domain_LINK"],
      // domain: "192.168.2.76",
      // domain: ".czy3d.com",
      httpOnly: false,
    });
    // 存储用户信息
    // Cookies.set(userKey, JSON.stringify(user), {
    //   expires: time,
    //   path: "/",
    //   // domain: ".czy3d.com",
    //   domain: "192.168.2.76",
    //   // domain: ".czy3d.com",
    //   httpOnly: false,
    // });
  }
}

//存储用户信息 两小时
export function setUserInfo(user) {
  saveUserInfo(user);
  store.commit("changeUserInfo", user);
}

//移除用户信息
export function removeUserInfo() {
  _localStorage.remove(userKey);
  Cookies.remove("editor_token");
  store.commit("changeUserInfo", null);
}
