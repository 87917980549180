<template>
  <!-- <el-dialog :close-on-click-modal="false" :destroy-on-close="true" :visible.sync="showDialog" append-to-body="false"
    custom-class="newDialogClass"> -->
  <section>
    <div>
      <div class="popover-title">当前选择方案</div>
      <img src="@/assets/price.svg" alt="" srcset="" class="price">
      <div class="scheme">{{ order.productName }}</div>
      <div class="scheme-desc center">{{ order.productDetail }}</div>
      <hr />
      <div class="popover-title">支付方式</div>


      <div class="order-info-wrapper">

        <div class="order-info">
          <div class="order-info-title">
            <!-- 微信二维码 -->
            <div class="qrcode-bg">
              <div id="knowledgeLink" ref="knowledgeLink" class="qrcode" v-if="showDialog">
                <div class="qrcode-bg" v-show="qrcodeBgShow"></div>
              </div>
            </div>
          </div>
          <div>

            <div class="scheme-desc">付费金额<br /><span>¥ {{ order.orderAmountTotal }}</span>&nbsp;/年</div>
            <div class="bottom-tips">
              <div v-show="orderPay == 1">请使用微信扫一扫</div>
              <div v-show="orderPay == 2">请在新打开的标签页扫码支付</div>
            </div>
            <div class="pay-mode-wrapper">
              <div class="pay-item" :class="{ 'pay-item-active': orderPay === 1 }" @click="orderPay = 1">
                <img src="@/assets/subscribe/wxzf.svg" alt="" />
              </div>
              <div class="pay-item" :class="{ 'pay-item-active': orderPay === 2 }" @click="orderPay = 2">
                <img src="@/assets/subscribe/zfb.svg" alt="" />

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="order-pay-btn" @click="orderPay">立即支付</div> -->
    </div>

    <!-- -->
    <!-- 支付宝支付 -->
    <div v-html="con" id="pay" v-show="orderPay == 2"></div>

  </section>
</template>

<script>
import { wxpay, prepay, orderStatus } from "@/api/pay/index.js";
import QRCode from "qrcodejs2";
import { setUserInfo } from "@/static/js/userInfo";
import { _localStorage } from "@/static/js/storage";
export default {
  props: {
    // showDialog: false,
  },
  data() {
    return {
      showDialog: false,
      order: {},
      con: "",
      timer: null,
      orderType: null,
      orderPay: -1, // 默认微信支付
      websocket: null,
      qrcodeBgShow: true //二维码背景
    };
  },
  watch: {
    orderPay(n) {
      // 微信支付
      if (n === 1 || n === -1) {
        this.getwxpay();
      }
      // 支付宝支付
      if (n === 2) {
        this.getprepay();
      }
    }
  },
  methods: {
    cancel() {
      this.showDialog = false;
      this.$store.dispatch("GetNewOrder");
      // this.$parent.showDialog = false
    },
    // 立即支付
    orderPay() { },
    init(order, orderType) {
      //默认微信支付
      this.showDialog = true;
      this.order = order;
      this.orderPay = 1;
      this.orderType = orderType;
      // this.getwxpay();
      window.onbeforeunload = function () {
        if (!!this.websocket) {
          this.closeWebSocket();
        }
      };
      // this.getprepay()
    },
    // 支付宝支付
    getprepay() {
      prepay({
        orderNo: this.order.orderNo,
        orderType: this.orderType,
        returnUrl: `${window.location.origin}/upgradePlan`
      }).then(res => {
        if (res.code === 200) {
          document.getElementById("pay").innerHTML = res.data;
          document
            .getElementById("pay")
            .getElementsByTagName("form")[0]
            .setAttribute("target", "_blank");
          document
            .getElementById("pay")
            .getElementsByTagName("form")[0]
            .submit();
        } else {
          this.$showMessage("e", res.message);
        }
      });
    },
    getwxpay() {
      this.qrcodeBgShow = true;
      wxpay({ orderNo: this.order.orderNo, orderType: this.orderType, returnUrl: `${window.location.origin}/upgradePlan` }).then(res => {
        if (res.code === 200) {
          this.creatQrCode(res.data.codeUrl);
          this.createSocket(res.data.socketId);
        } else {
          this.$showMessage("e", res.message);
        }
      });
    },
    creatQrCode(codeUrl) {
      document.getElementById("knowledgeLink").innerHTML = "";
      new QRCode(this.$refs.knowledgeLink, {
        text: codeUrl, // 需要转换为二维码的内容
        width: 140,
        height: 140,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.M
      });
      this.qrcodeBgShow = false;
      // this.$nextTick(function() {
      //   if (document.getElementById("knowledgeLink")?.children.length > 0) {
      //     document.getElementById("knowledgeLink").innerHTML = "";
      //   }

      //   new QRCode(this.$refs.knowledgeLink, {
      //     text: codeUrl, // 需要转换为二维码的内容
      //     width: 160,
      //     height: 160,
      //     colorDark: "#000000",
      //     colorLight: "#ffffff",
      //     correctLevel: QRCode.CorrectLevel.M
      //   });
      //   this.qrcodeBgShow = false;
      // });
    },

    sure() {
      this.orderPay = this.orderPay == 1 ? 2 : 1;
      this.orderPay == 1 ? this.getwxpay() : this.getprepay();
    },
    // 建立websocket连接
    createSocket(ticket) {
      let that = this;
      if ("WebSocket" in window) {
        this.websocket = new WebSocket(
          // 'ws://192.168.2.28:9003/editor_server/websocket/' + ticket
          process.env["VUE_APP_WEBSOCKET"] + ticket
        ); // 后台给的地址
        console.log("数据连接", this.websocket); // 这里会传递一些数据，
        //连接成功建立的回调方法
        this.websocket.onopen = function () {
          //setMessageInnerHTML("WebSocket连接成功");
          console.log("WebSocket连接成功");
        };
        //接收到消息的回调方法
        this.websocket.onmessage = function (event) {
          let data = JSON.parse(event.data);
          if (data.content == "SUCCESS" && data.type == "wxNativePay") {
            that.closeWebSocket();
            that.$showMessage("s", "支付成功");
            // 跳转到回调支付页
            window.location.reload()
            // that.showDialog = false;
            // that.$emit("initTable");
            // that.$parent.type = r.data.type;
          }
          console.log("接收到消息的回调方法", event);
        };
      }
    },
    // 关闭Socket连接
    closeWebSocket() {
      console.log("websocket关闭", this.websocket);
      this.websocket.close();
    }
  }
};
</script>

<style scoped lang="scss">
.qrcode-bg {
  width: 160PX;
  height: 160PX;
  background-color: #ffffff;
  padding: 10PX;
  background: #ededed;
  border-radius: 10PX;
}

.bottom-tips {
  font-weight: 500;
  font-size: 14PX;
  line-height: 24PX;
  color: #52555a;
  padding-top: 10PX;
  text-align: left;
}

.order-pay-btn {
  margin: 0 auto;
  width: 200PX;
  height: 44PX;
  border-radius: 10PX;
  font-family: PingFang SC;
  font-size: 16PX;
  font-weight: 600;
  line-height: 44PX;
  text-align: center;
  color: #fff;
  background-color: rgba(255, 69, 0, 1);
  cursor: pointer;
}

.popover-content {
  position: absolute;
  right: -100PX;
  bottom: 0;
  z-index: 1;
  width: 400PX;
  height: 440PX;
  border-radius: 20PX;
  background: #fff;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s;
  font-size: 20PX;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2PX solid rgba(255, 255, 255, 1);
  box-shadow: 0PX 15PX 30PX 0PX rgba(0, 0, 0, 0.15);

  >span {
    padding-bottom: 30PX;
  }

  .popover-title {
    font-family: PingFang SC;
    font-size: 16PX;
    font-weight: 600;
    line-height: 22.4PX;
    text-align: left;
    color: rgba(51, 51, 51, 1);
    margin-left: 34PX;
    margin-bottom: 12PX;
  }

  .scheme {
    font-family: PingFang SC;
    font-size: 24PX;
    font-weight: 600;
    line-height: 40PX;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    text-align: center;
  }

  .scheme-desc {
    font-family: PingFang SC;
    font-size: 14PX;
    font-weight: 400;
    line-height: 26PX;
    text-align: left;
    color: rgba(85, 85, 85, 1);
    margin-bottom: 10PX;

    span {
      display: inline-block;
      font-family: PingFang SC;
      font-size: 32PX;
      font-weight: 600;
      line-height: 40PX;
      text-align: left;
      color: rgba(255, 69, 0, 1);

    }
  }

  .center {
    text-align: center;
  }

  hr {
    border-bottom: 0PX solid rgba(216, 216, 216, 1);
    border-color: rgba(216, 216, 216, 1);
    margin-bottom: 28PX;
  }

  >img {
    width: 220PX;
    height: 220PX;
  }

  .price {
    width: auto;
    height: auto;
  }
}

::v-deep .el-dialog {
  width: 400PX;
  height: 480PX;
  background-color: #fff !important;



  .border {
    height: 1PX;
    width: 100%;
    background-color: #aeaeb2;
  }

  .qrcode {
    display: flex;
    width: 100%;
    justify-content: center;
    padding-right: 10PX;
    background-color: #eee;
    border-radius: 20PX;


  }



  .zfb-tips {
    font-weight: 500;
    font-size: 14PX;
    line-height: 24PX;
    color: #52555a;
    padding-top: 70PX;
  }
}

.order-info-wrapper {
  width: 100%;
  padding: 0 20PX;

  .order-info {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .order-info-title {
      font-weight: 500;
      font-size: 14PX;
      line-height: 24PX;
      color: #48484a;
    }

    .order-info-content {
      font-weight: 500;
      font-size: 16PX;
      line-height: 38PX;
      color: #000000;

      &-price {
        color: #e6541b;
      }
    }

    .pay-mode-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .pay-item {
        width: 120PX;
        height: 52PX;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8PX;
        cursor: pointer;
        border: 2PX solid transparent;
        position: relative;
        border: 1PX solid #eee;

        >img {
          width: 90PX;
        }

        &-active {
          border-color: #e6541b;
        }

        .zfb-tip {
          position: absolute;
          bottom: -24PX;
          font-weight: 500;
          font-size: 12PX;
          line-height: 18PX;
          color: #888888;
        }
      }

      .pay-item+.pay-item {
        margin-left: 20PX;
      }
    }
  }
}
</style>
