<!--
 * @Author: your name
 * @Date: 2021-11-24 09:19:06
 * @LastEditTime: 2022-03-15 16:50:29
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \editor_pc\src\components\head\LoginDialog.vue
-->
<template>
  <div class="loginOpearate">
    <!-- <div class="us" :class="userName ? 'loginuname' : 'unloginuname'" @click="userPageOrRegist()" v-if="userName"> -->
      <!-- <img :src="avatar ? avatar : defultHeader" class="avatar" /> -->
      <!-- <div>
        <div class="phone">{{ userName }}</div> -->
        <!-- <div class="phone">{{ phone }}</div> -->
      <!-- </div>
    </div> -->
    <div class="btn" v-for="(item, index) in naves" :key="index" @click="selectItem(index)">{{ item.name }}</div>

    <div @click="loginOrExsit()" class="btn quit">
      {{ userName ? "退出登录" : "注册/登录" }}
    </div>
  </div>
</template>

<script>
import { removeUserInfo } from "@/static/js/userInfo";
import { mapState } from "vuex";
import { getUserInfo } from "@/static/js/userInfo";
import { query } from "quill";
export default {
  computed: {
    ...mapState(["userInfo"])
  },
  data() {
    return {
      userName: null,
      user: null,
      avatar: "",
      phone: "",
      defultHeader: require("@/assets/avatar_default.png"),
      naves: [
        { name: "开发者后台", path: "/home/dashboard", icon: "space-overview" },
        { name: "个人中心", path: "/newMineHome", icon: "space-overview" },
        // { name: "空间作品", path: "/works", icon: "space-manage" },
        // { name: "管理团队", path: "/spacedetaile", icon: "space-works" }
      ]
    };
  },
  watch: {
    userInfo(val) {
      if (val && val.userId) {
        this.userName = val && val.username ? val.username : val.nickname;
        this.avatar = val.avatar;
        this.phone = val.user?.tel;
        this.user = val;
      } else {
        this.avatar = null;
        this.userName = null;
        this.user = null;
      }
    }
  },
  mounted() {
    this.user = getUserInfo();
    if (this.user) {
      this.avatar = this.user?.avatar;
      this.userName = this.user && this.user.username ? this.user.username : this.user.nickname;
      this.phone = this.user?.tel;
    } else {
      this.userName = null;
      this.user = null;
    }
  },
  methods: {
    selectItem(index) {
      this.$router.push({ path: this.naves[index].path, query: index == 2 ? this.user.mainSpace : "" });
    },
    loginOrExsit() {
      if (this.userName) {
        this.userName = null;
        this.user = null;
        removeUserInfo();
        if (this.$route.meta.requiresAuth) {
          this.$router.push("/");
        }
        this.$store.commit("changeTopOrderInfo", null);
      } else {
        //登录-弹出登录框
        this.$store.commit("changeLoginDialogVisible", 7);
      }
    },
    userPageOrRegist() {
      if (this.userName) {
        this.$router.push("/mineHome");
      } else {
        this.$store.commit("changeLoginDialogVisible", 2);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.us {
  font-size: 20px !important;
  cursor: pointer;
  display: flex;
  padding-bottom: 10px;
  border-bottom: 1px solid #6f6f6f;
  margin-bottom: 10px;
}

.btn {
  width: 94%;
  text-align: left;
  cursor: pointer;
  color: #555555 !important;
  margin: 0;
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 500;
  &:first-child {
    margin-top: 6px;
  }
}

.btn:hover {
  border-radius: 6px;
  // background: rgba(255, 102, 0, 1);
  color: #ff6600 !important;
  background: #ccc;
}

.quit {
  margin-bottom: 10px;
}

.quit:hover {
  border-radius: 6px;
}

.avatar {
  width: 64px;
  height: 64px;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 12px;
}

.loginuname {
  font-weight: 600;
  font-size: 18px;
  // line-height: 50px;

  display: flex;
  align-items: center;
  color: #111821;
}

.phone {
  font-weight: 600;
  font-size: 12px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  color: #fff;
}
</style>
