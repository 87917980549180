    <!-- 2024年7月6日17:02:52 新增menu组件 -->
<template>
  <div class="header-navs">
    <div class="nav_list" @mouseenter="isShowFrame = true" @mouseleave="isShowFrame = false">
      <span v-for="(item, index) in navs" :key="index">
        <div class="nav_title" slot="reference" @click="navEvent(item)"
          :class="navIndex == item.name ? 'check-title' : ''" @mouseenter="isNavIndex = index">
          {{ item.name }}
        </div>
      </span>
    </div>

    <div v-show="isShowFrame" class="nav_frame wow animate__fadeIn" data-wow-duration=".6s" data-wow-delay="0s"
      data-wow-iteration=".6s" @mouseenter="isShowFrame = true" @mouseleave="isShowFrame = false">
      <div class="nav_frame_main wow animate__fadeIn" data-wow-duration="1s" data-wow-delay="0s"
        data-wow-iteration="1s">
        <!-- 鼠标选停在产品矩阵、解决方案时显示 -->
        <div class="nav_frame_main_item" v-if="isNavIndex == 1 || isNavIndex == 2"
          v-for="(i, ind) in navs[isNavIndex].sublevel">
          <div class="nav_frame_main_item_title">
            {{ i.name1 }}
          </div>
          <div class="nav_frame_main_item_box" v-for="(its, index) in i.sub" :key="its.path" @click="getUseApp(its)">
            {{ its.name }}
          </div>
        </div>
        <!-- 鼠标选停在别的menu时显示 -->
        <div class="nav_frame_main_item" v-if="isNavIndex == index" v-for="(item, index) in navs" :key="index"
          :style="{ marginLeft: (index + 1) == navs.length ? isNavIndex * (97.5 / navs.length) + '%' : isNavIndex * (95 / navs.length) + '%' }">
          <div class="nav_frame_main_item_box" v-for="(i, j) in item.sublevel" :key="j" @click="navEvent(i, item.name)">
            {{ i.name }}
          </div>
        </div>
      </div>
    </div>
    <Namespacedialog :showDialog="showDialog" :spaceId="spaceId" @close="showDialog = false"></Namespacedialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { requireAuthBefore } from "@/static/js/authDecorators";
import { querySearchMainSpace } from "@/api/space/index.js";
import Namespacedialog from "@/views/createProject/namespacedialog.vue";
import { appClickHandle } from "@/static/js/goPage.js";
import { WOW } from "wowjs";

export default {
  name: "NavList",
  components: { Namespacedialog },
  data: function () {
    return {
      showDialog: false,
      spaceId: "",
      navIndex: "",
      checkNune: "",
      navs: [
        {
          width: 250,
          name: "炽橙科技",
          path: "/",
          level: 0,
          sublevel: [
            {
              name: "关于炽橙",
              path: "/aboutCZY",
            },
            {
              name: "选择超真云",
              path: "/chooseCZY",
            },
            {
              name: "精彩超真云",
              path: "/suchPerson",
            },
            {
              name: "加入我们",
              path: "https://www.zhipin.com/gongsi/d6bf748c4814401b0HZ72N60GQ~~.html?ka=search_rcmd_company_name_d6bf748c4814401b0HZ72N60GQ~~_custompage",
            },
          ],
        },

        {
          width: -1,
          name: "产品矩阵", level: 0,
          path: "",
          sublevel: [
          ],
        },
        {
          width: -1, level: 0,
          name: "解决方案",
          path: "",
          sublevel: [
            {
              name1: "智能制造",
              sub: [
                {
                  name: "工业产品数据管理解决方案",
                  path: "/schema?id=1",
                },
                {
                  name: "新能源数字化交付解决方案",
                  path: "/schema?id=2",
                },
                {
                  name: "先进制造数字孪生体解决方案",
                  path: "/schema?id=3",
                },
                {
                  name: "工业垂直模型与智能应用解决方案",
                  path: "/schema?id=4",
                },
              ],
            },
            {
              name1: "全域赋能",
              sub: [
                {
                  name: "装备技术保障大模型一体化解决方案",
                  path: "/schema?id=5",
                },
                {
                  name: " 城市数字孪生解决方案",
                  path: "/schema?id=6",
                },
              ],
            },
          ],
        },
        {
          width: 250,
          name: "应用与作品", level: 0,
          path: "",
          sublevel: [
            {
              name: "应用平台",
              path: "/appPlatform",
            },
            {
              name: "最新作品",
              path: "/appPlatform?sidx=NEW_LIST",
            },
            {
              name: "炽橙杯3D创智大赛",
              path: "/contest/hgd",
            },
          ],
        },
        {
          width: 250,
          name: "资源中心", level: 0,
          path: "",
          sublevel: [
            {
              name: "模型平台",
              path: "/modelPlat",
            },
            {
              name: "服务支持",
              path: "/czy-serve",
            },
          ],
        },
      ],

      isShowFrame: false, // 是否显示框架
      isNavIndex: 0, // 导航索引
    };
  },
  watch: {
    $route(to, from) {
      if (to.path == from.path && from.path != "/") {
        return;
      }
      if (to.path == "/case") {
        this.navIndex = 1;
      }
      if (to.path == "/") {
        this.navIndex = -1;
      }
    },
  },
  computed: {
    ...mapGetters(["appList"]),
  },
  watch: {
    // 监听当前路由
    appList(n) {
      if (n) {
        this.navs[1]["sublevel"] = [];
        let arr = Array.from(new Set(n.map((it) => it.groupName)));
        arr.map((it, index) => {
          this.navs[1]["sublevel"].push({
            name1: it,
            sub: [],
          });
          n.map((i) => {
            if (it == i.groupName) {
              this.navs[1]["sublevel"][index]["sub"].push({
                name: i.name.replaceAll("|", ""),
                path: i.czyRouter,
                type: 0,
              });
            }
          });
        });
      }
    },
  },
  mounted() {
    this.$store.dispatch("getuseAppList");
    this.$nextTick(() => {
      new WOW().init();
    });
  },
  methods: {
    getUseApp(it) {
      try {
        TDAPP.onEvent(it.name.replaceAll(" ", ""));
      } catch (error) {

      }

      if (it.type == 0) {
        this.toProduct(it);
      } else {
        this.toSchema(it);
      }
    },
    @requireAuthBefore()
toProduct(it) {
  it.goPageStatus = 1;
  this.$store.commit("chanPageUrl", it);
  appClickHandle(it);
},
toSchema(it) {
  this.$router.push({ path: it.path });
},
goSearch() {
  this.$router.push("/search");
},
navEvent(r, mune) {
  try {
    TDAPP.onEvent(r.name.replaceAll(" ", ""));
  } catch (error) {

  }
  if (r.level != 0) {
    this.isShowFrame = false
  }
  this.$parent.isHint = false;
  this.navIndex = r.name;
  this.checkNune = mune;
  if (r.path.includes("http")) {
    window.open(r.path, "_blank");
    return;
  }
  this.$router.push({ path: r.path });
},
  },
};
</script>
<style>
.meun-project {
  margin-left: -26px !important;
}
</style>
<style scoped lang="scss">
.not-subheading {
  color: #888888 !important;
  cursor: not-allowed !important;
  pointer-events: none;
  user-select: none;
}

.check-title {
  color: #ff4500 !important;

  &:hover {
    color: #ff4500 !important;
  }
}

.nav-sub {
  width: 50%;
  display: block;

  &-title {
    text-align: left;
    padding-left: 14px;
    font-family: PingFang SC;
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    color: rgba(136, 136, 136, 1) !important;
  }
}

.nav-btn {
  height: 30px;
  cursor: pointer;
  font-family: PingFang SC;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 10px;
  padding-left: 14px;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  display: flex;
  align-items: center;

  &:hover {
    // background-color: rgba(255, 102, 0, 1);
    border-radius: 6px;
    color: #ff4500;
  }
}

.nav_title {
  font-family: PingFang SC;
  font-size: 16px;

  line-height: 22.4px;
  text-align: center;
  color: rgba(0, 0, 0, 1);
  cursor: pointer;

  &:hover {
    color: #ff4500;
  }
}

.nav_list {
  width: 600px;
  justify-content: space-between;
  cursor: pointer;
}

.nav_frame {
  position: absolute;
  left: 0px;
  height: auto;
  width: 100vw;
  background-color: #fff;
  z-index: -1;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;

  &_main {
    display: flex;
    justify-content: space-between;
    width: 690px;
    margin-left: -20px;

    // margin-left: -80px;
    &_item {
      color: #000;
      font-size: 18px;
      text-align: left;
      margin-right: 30px;

      &_title {
        // margin-top: 10px;
        line-height: 34px;
        font-size: 14px;
        color: #555;
      }

      &_box {
        cursor: pointer;
        margin-top: 15px;
        white-space: nowrap;

        &:hover {
          color: #ff4500;
        }
      }
    }
  }
}
</style>
