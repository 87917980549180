<template>
  <section>
    <!-- <div class="touchDiv" id="touchView">
    <div class="touch">
      <div class="touchLeft">
        <svg-icon icon-class="logo_new" class="logo"></svg-icon>
        <div v-for="(item, index) in links" :key="index" class="touchItems">
          <span class="name">{{ item.name }}</span>
          <div v-if="item.type">
            <span class="content">{{ item.content }}</span>
            <span class="content" style="margin-left: 60px;">{{ item.towContent }}</span>
          </div>
          <span class="content" v-else>{{ item.content }}</span>
        </div>
        <div>
          <img src="@/assets/contactus.jpg" class="gonghzonghao" />
          <img src="@/assets/HomeIcon/qrcode-chicheng.png" class="gonghzonghao" style="margin-left: 20px" />
        </div>
      </div>
      <div class="touchRight">
        <span class="tittleTouch">联系我们</span>
        <div class="tittileTouchPhone" style="display: none">
          <div>联络我们</div>
          <svg-icon icon-class="logo_new" class="logo"></svg-icon>
        </div>
        <div class="touchlist">
          <div v-for="(item, index) in touchlist" :key="index" class="touchItem">
            <div :class="!item.auth ? 'touchName' : 'touchNameError'">
              {{ item.name }}
            </div>
            <input :type="item.type" class="inputContet" v-model="item.content" onmousewheel="stopScrollFun"
              onDOMMouseScroll="stopScrollFun" />
          </div>
        </div>
        <div class="helpTittle">
          <span class="tittle">您可能需要那些帮助？</span><span class="choosetiee">(可多选)</span>
        </div>
        <div class="helplist">
          <div v-for="(item, index) in helps" :key="item" :class="chooseHelp[chooseHelp.indexOf(item)] == item
          ? 'chooseHelp'
          : 'Help'
          " @click="chooseHelpMe(index, item)">
            {{ item }}
          </div>
        </div>
        <div class="sendDiv">
          <div class="hint">※ 我们会在 48 小时内回复</div>
          <div class="sendBtn" @click="submit()">发送</div>
        </div>
      </div>
    </div>
  </div> -->
  </section>
</template>

<script>
import { touchUsSubmit } from "@/api/common/params";
import "@/static/css/touchUs.scss";
export default {
  data() {
    return {
      links: [
        {
          name: "服务电话",
          content: "400 618 0681",
        },
        {
          name: "商务邮箱",
          content: "bddongiian@ccengine.com",
        },
        {
          name: "媒体联系",
          content: "超真云服务号",
          type: 'clo',
          towContent: '炽橙科技公众号'
        },
      ],
      touchlist: [
        {
          name: "公司名称 *",
          content: "",
          auth: false,
          type: "text",
        },
        {
          name: "您的姓名 *",
          content: "",
          auth: false,
          type: "text",
        },
        {
          name: "所属行业",
          content: "",
          auth: false,
          type: "text",
        },
        {
          name: "所在城市",
          content: "",
          auth: false,
          type: "text",
        },
        {
          name: "联系电话 *",
          content: "",
          auth: false,
          type: "text",
        },
        {
          name: "您的邮箱",
          content: "",
          auth: false,
          type: "email",
        },
      ],
      screenHeight: 0,
      chooseHelp: [],
      helps: ["产品内容定制", "在线业务拓展", "云引擎反馈", "合作跟进", "其他"],
      data: {
        address: "",
        companyName: "",
        contactName: "",
        contactPhone: "",
        email: "",
        industry: "",
        requireDes: "",
      },
    };
  },
  mounted() {
    this.screenHeight = document.documentElement.clientHeight;
  },
  methods: {
    stopScrollFun(evt) {
      evt = evt || window.event;
      if (evt.preventDefault) {
        // Firefox
        evt.preventDefault();
        evt.stopPropagation();
      } else {
        // IE
        evt.cancelBubble = true;
        evt.returnValue = false;
      }
      return false;
    },
    submit() {
      if (this.touchlist[0].content == "") {
        //console.log("公司名", this.touchlist[0].content);
        this.touchlist[0].auth = true;
        return;
      } else {
        this.touchlist[0].auth = false;
      }

      if (this.touchlist[1].content == "") {
        //console.log("昵称", this.touchlist[1].content);
        this.touchlist[1].auth = true;
        return;
      } else {
        this.touchlist[1].auth = false;
      }

      if (this.touchlist[4].content == "") {
        //console.log("联系方式", this.touchlist[4].content);
        this.touchlist[4].auth = true;
        return;
      } else {
        this.touchlist[4].auth = false;
      }
      this.data.address = this.touchlist[3].content;
      this.data.companyName = this.touchlist[0].content;
      this.data.contactName = this.touchlist[1].content;
      this.data.contactPhone = this.touchlist[4].content;
      this.data.email = this.touchlist[5].content;
      this.data.industry = this.touchlist[2].content;
      this.data.requireDes = this.chooseHelp.join(",");
      this.touchlist.forEach((item) => {
        item.content = "";
      });

      touchUsSubmit(this.data).then((res) => {
        //console.log("返回", res);
        if (res.code == 200) {
          this.data.address = "";
          this.data.companyName = "";
          this.data.contactName = "";
          this.data.contactPhone = "";
          this.data.email = "";
          this.data.industry = "";
          this.data.requireDes = "";
          this.$showMessage("s", "提交成功");
        } else {
          this.$showMessage("s", res.message);
        }
      });
    },

    chooseHelpMe(index, item) {
      var help = this.helps[index];
      if (this.chooseHelp.indexOf(help) == -1) {
        this.chooseHelp.push(help);
      } else {
        let ids = this.chooseHelp.indexOf(item);
        this.chooseHelp.splice(ids, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
