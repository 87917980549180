export function debounce(fn, delay) {
  //console.log(fn);
  // 记录上一次的延时器
  var timer = null;
  var delay = delay || 200;
  return function () {
    var args = arguments;
    var that = this; // 清除上一次延时器
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

export function formatDate(date) {
  var date = new Date(date);
  var YY = date.getFullYear() + "/";
  var MM = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "/";
  var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  var hh = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  var mm = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
  var ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return YY + MM + DD;
  return YY + MM + DD + " " + hh + mm + ss;
}

//  强制保留2位小数，如：2，会在2后面补上00.即2.00
export function toDecimal2(x) {
  var f = parseFloat(x);
  if (isNaN(f)) {
    return false;
  }
  var f = Math.round(x * 100) / 100;
  var s = f.toString();
  var rs = s.indexOf(".");
  if (rs < 0) {
    rs = s.length;
    s += ".";
  }
  while (s.length <= rs + 2) {
    s += "0";
  }
  return s;
}

export function checkIsMobile() {
  let userAgent = navigator.userAgent.toLowerCase();
  return /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(userAgent) && window.screen.width <= 768;
}

export function isAppleDevice() {
  // 获取用户代理字符串
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // 判断是否为 iOS 设备
  const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;

  // 判断是否为 macOS 设备
  const isMac = /Macintosh/.test(userAgent);

  // 返回是否为苹果设备
  return isIOS || isMac;
}
