export default [
  {
    path: "/marketing",
    name: "marketing",
    component: () => import("@/views/marketing/MarketingBase.vue"),
    children: [
      {
        path: "",
        redirect: "240506",
      },
      {
        path: "240506",
        name: "marketing-20240506",
        component: () => import("@/views/marketing/20240506/index.vue"),
        meta: {
          title: "超真云·3D手册",
          hiddenFooter: true,
          hiddenNav: true,
          canInMobile: true,
        },
      },
      // {
      //   path: "240517",
      //   name: "marketing-20240517",
      //   component: () => import("@/views/marketing/20240517/index.vue"),
      //   meta: {
      //     title: '2024 "炽橙杯" - 杭州炽橙科技数字有限公司 x 合肥工业大学',
      //     hiddenFooter: true,
      //     hiddenNav: true,
      //   }
      // }
    ],
  },
  {
    path: "/contest",
    name: "contest",
    component: () => import("@/views/marketing/MarketingBase.vue"),
    children: [
      {
        path: "hgd",
        name: "marketing-20240517",
        component: () => import("@/views/marketing/20240517/index.vue"),
        meta: {
          title: '2024 "炽橙杯" - 杭州炽橙科技数字有限公司 x 合肥工业大学',
          hiddenFooter: true,
          hiddenNav: true,
          canInMobile: true,
        },
      },
    ],
  },
];
