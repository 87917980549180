export default {
  "n1": "Born for Industry",
  "n2": "Make digitalization simpler",
  "n3": "Hangzhou Chicheng Digital Technology Co., Ltd. (Chicheng Technology) was established in 2015, led by Academician Tan Jianrong of the Chinese Academy of Engineering. It has long focused on industrial data intelligence and human-computer collaborative interaction technology, and is leading in the field of independently controllable industrial graphics engines and digital twins in China. Our business scope covers intelligent manufacturing, military civilian integration, vocational education, and has served dozens of state-owned enterprises such as four major nuclear power groups and China Shipbuilding Industry Corporation, as well as more than 1200 multinational and private manufacturing enterprises such as Bosch China, Geely Automobile, and Theo Elevator, and more than 100 research institutes such as Zhejiang University and Hefei University of Technology.",
  "n4": "Chicheng Technology has been honored as a national high-tech enterprise, a provincial-level specialized and innovative small and medium-sized enterprise, and a 2024 industrial intelligent digital transformation influential enterprise. It has established a provincial-level high-tech enterprise research and development center and jointly built the \"Zhejiang University Chicheng Advanced Intelligent Digital Twin Joint Research Center\" with Zhejiang University. The \"Domestic Industrial Metaverse Intelligent Interaction Base\" has won the 2023 Zhejiang Province Advanced (Future) Technology Innovation Achievement, the \"Digital Twin Factory for Advanced Manufacturing Industry\" project has been selected as one of the first typical cases of \"New Quality Productivity in Zhejiang\" in 2024, and the \"Double Ring Transmission Future No.1 Digital Factory\" project has been selected as a \"multiplier list\" of typical application scenarios for digital commerce in 2024.",
  "n5": "Latest News",
  "n6": "ChiCheng Partner Program",
  "n7": "Building an open, cooperative, and mutually beneficial ecosystem of new quality productive forces",
  "n8": "Become a partner",
  "n9": "Business opportunity sharing",
  "n10": "Targeting value scenarios across various industries",
  "n11": "Sharing business opportunities in advantageous fields",
  "n12": "Joint plan",
  "n13": "Integrate products and services from both parties",
  "n14": "Joint expansion solution",
  "n15": "Product Distribution ",
  "n16": "Authorized Ecological Distribution within the Territory",
  "n17": "Win win cooperation in technology and service",
  "n18": "mission",
  "n19": "Smart new engine drives productivity",
  "n20": "vision",
  "n21": "Become a leader in the field of industrial intelligent interaction",
  "n22": "sense of worth",
  "n23": "Sincere, pragmatic, outstanding, innovative",
  "n24": "Striving for progress, achieving mutual benefits, and reaping rewards",
  "n25": "team spirit",
  "n26": "Passion and excellence, innovation and extraordinary",
  "n27": "#3D Creative Intelligence Competition",
  "n28": "Update Date",
  "n29": "Scan the QR code on WeChat to share articles",
  "n30": "search",
  "n31": "All of them",
  "n32": "Selected applications",
  "n33": "All applications",
  "n34": "newest",
  "n35": "The hottest",
  "n36": "Hefei University of Technology X Chi Orange Technology<br/>\"Chi Orange Cup\" 3D Creative Intelligence Competition",
  "n37": "As simple as a hair circle<br/>Super True Cloud Manual AI Generation",
  "n38": "XIAOMI SU7<br/>Super True Cloud Manual"
}