//尽可能保持router尽快加载，影响 pc 端和 移动端互跳
import router from "./router";
import Vue from "vue";
import "@/router/hooks/permission";
import App from "./App.vue";
import store from "./store";
import ElementUI from "element-ui";
import Vuex from "vuex";
import showMessage from "@/static/js/message";
Vue.prototype.$showMessage = showMessage;
import VueTouch from "vue-touch";
import "@/icons/index"; // icon
import "@/static/css/main.scss";
import "@/static/css/transition.scss";
import { translate } from "@/api/mine/index.js";
/*fa-icon*/
// import "font-awesome/css/font-awesome.min.css";
import { initVue } from "@/init/initVue";
// 引入动画样式
import "animate.css";
import VueLazyload from "vue-lazyload";
Vue.use(ElementUI);
Vue.use(Vuex);
import "@/static/js/fexilb.js";
import zh from "../i18n/zh/zh.mjs";
import en from "../i18n/en/en.mjs";
import VueI18n from "vue-i18n";
Vue.use(VueTouch, {
  name: "v-touch",
});
initVue();

Vue.use(VueLazyload, {
  // 设置懒加载时的占位图
  loading: "path/to/loading-image.gif",
  // 设置图片加载失败时的占位图
  error: "path/to/error-image.png",
});

const keyMap = new Map();
for (let key in zh) {
  keyMap.set(zh[key], key);
}
const messages = {
  en_lang: en,
  zh_lang: zh,
};

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: "zh_lang",
  messages,
});
function transform(target, params) {
  return target.replace(/f(.+?)}/g, (a, key) => {
    return params[key];
  });
}
Vue.prototype.$T = function (value, ...arg) {
  let key = keyMap.get(value);
  if (i18n.locale === "zh_lang") {
    if (arg.length) {
      return transform(value, arg[0]);
    } else {
      return value;
    }
  } else {
    return this.$t(key, ...arg);
  }
};
Vue.prototype.$TV = function (value, ...arg) {
  //   debugger;
  //   dataVal = value;

  //   translate(value).then((res) => {
  //     dataVal = res.data[0]["dst"];
  //     setTimeout(() => {
  //       this.$t(value, res.data[0]["dst"]);
  //       return res.data[0]["dst"];
  //     }, 500);
  //   });

  return value; //data[0]["dst"];
};
store.watch(
  (state) => state.language,
  (newValue, oldValue) => {
    i18n.locale = newValue;
    console.log(`count has changed from ${oldValue} to ${newValue}`);
  }
);
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
