<template>
  <div>
    <el-dialog
      custom-class="contactus-dialog"
      title=""
      :visible.sync="dialogVisible"
      width="720px"
      :show-close="true"
      :close-on-click-modal="true"
      @close="setContactusDialog(false)"
    >
      <img src="@/assets/newImg/0704/logo.svg" alt="" />
      <el-form ref="form" :model="form" label-position="top" :rules="rules" v-if="!isMobile">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="姓名 / 职位 " prop="contactName">
              <el-input v-model="form.contactName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公司 / 行业 " prop="companyName">
              <el-input v-model="form.companyName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="电话 " prop="contactPhone">
              <el-input v-model="form.contactPhone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="微信 " prop="wx">
              <el-input v-model="form.wx"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form ref="form" :model="form" label-position="top" :rules="rules" v-else>
        <el-form-item label="姓名 / 职位 *" prop="contactName">
          <el-input v-model="form.contactName"></el-input>
        </el-form-item>
        <el-form-item label="公司 / 行业 *" prop="companyName">
          <el-input v-model="form.companyName"></el-input>
        </el-form-item>
        <el-form-item label="电话 *" prop="contactPhone">
          <el-input v-model="form.contactPhone"></el-input>
        </el-form-item>
        <el-form-item label="微信 " prop="wx">
          <el-input v-model="form.wx"></el-input>
        </el-form-item>
      </el-form>
      <div class="requireDes-select">
        <div class="title">您可能需要的服务？（可多选）</div>
        <div class="select-list">
          <div
            class="select-item"
            :class="{
              active: form.requireDes.includes(item),
            }"
            v-for="(item, index) in select"
            :key="index"
            @click="addRequireDes(item)"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="btn" @click="handleConfig">发送</div>
    </el-dialog>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { touchUsSubmit } from "@/api/common/params.js";
import { mapState, mapMutations } from "vuex";
import { isMobileNumber } from "@/static/js/validate.js";
export default {
  props: {},
  data() {
    return {
      dialogVisible: false,
      form: {
        contactName: "",
        contactPhone: "",
        wx: "",
        requireDes: ["合作伙伴"],
        source: "商务咨询",
        companyName: "",
      },
      select: ["内容定制", "售前咨询", "售后服务", "客户成功", "投诉反馈", "合作伙伴"],
      rules: {
        contactName: [{ required: true, message: "请输入姓名 / 职位", trigger: "blur" }],
        companyName: [{ required: true, message: "请输入公司 / 行业", trigger: "blur" }],
        contactPhone: [
          { required: true, message: "请输入电话", trigger: "blur" },
          { validator: isMobileNumber, trigger: "blur" },
        ],
        // wx: [{ required: true, message: "请输入微信", trigger: "blur" }],
      },
    };
  },
  mounted() {},
  computed: {
    ...mapState(["contactusDialog", "isMobile"]),
  },
  watch: {
    contactusDialog(v) {
      if (v) {
        this.dialogVisible = true;
        this.$nextTick(function () {
          this.$refs["form"].resetFields();
        });
      } else {
        this.dialogVisible = false;
      }
    },
  },
  methods: {
    ...mapMutations(["setContactusDialog"]),
    addRequireDes(select) {
      if (this.form.requireDes.includes(select)) {
        this.form.requireDes = this.form.requireDes.filter((item) => item !== select);
      } else {
        this.form.requireDes.push(select);
      }
    },
    handleConfig() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const form = cloneDeep(this.form);
          form.requireDes = this.form.requireDes.join(",");
          touchUsSubmit(form).then((res) => {
            this.$showMessage("s", "发送成功，超真云将尽快与您联络");
            this.dialogVisible = false;
            this.form = {
              contactName: "",
              contactPhone: "",
              wx: "",
              requireDes: ["合作伙伴"],
              source: "商务咨询",
            };
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.contactus-dialog {
  @media screen and (max-width: 768px) {
    width: 90vw !important;
  }

  .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before,
  .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
    display: none;
  }

  .el-form-item__error {
    color: #ffb228 !important;
    font-family: PingFang SC;
    font-size: 12px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
  }

  .requireDes-select {
    margin-top: 10px;

    .title {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      text-align: left;
      color: #ffffff;
    }

    .select-list {
      display: flex;
      justify-content: space-around;
      margin-top: 11px;
      margin-bottom: 43px;

      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: center;

        .select-item {
          margin: 10px;
        }
      }

      .select-item {
        font-family: PingFang SC;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        line-height: 30px;
        color: #ffffff;
        cursor: pointer;
        width: 77px;
        height: 30px;
        border-radius: 10px;
        background-color: transparent;

        &.active {
          background-color: #b2a2fd;
        }
      }
    }
  }

  // .el-dialog__header {
  //   display: none;
  // }

  .el-form-item__label {
    width: 100%;
    color: #ffffff;
  }

  .el-input .el-input__inner {
    background-color: #bebebe;
    border: 1px solid #ffffff !important;
    color: #555555 !important;
  }

  .btn {
    width: 100%;
    height: 48px;
    background-color: #ff4500;
    font-family: PingFang SC;
    font-size: 16px;
    font-weight: 600;
    line-height: 48px;
    text-align: center;
    color: #fff;
    border-radius: 10px;
    cursor: pointer;
  }
}
</style>
