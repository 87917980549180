<!-- 创建应用 -->
<template>
  <div class="bg">

    <div class="app-group" v-for="(item, ids) in apps" :key="item.ids">
      <div class="content">
        <div class="title">{{ appKeyArr[ids] }}</div>
        <div class="card-name">
          <div :class="['app-item', { pointer: app.path || app.link }]" v-for="(app, index) in item" :key="app.key"
            @click="appClickHandle(app)">
            {{ app.name }}
          </div>
        </div>
      </div>
    </div>
    <Namespacedialog :showDialog="showDialog" :appData="appData" :spaceId="spaceId"></Namespacedialog>
  </div>
</template>
<script>
import { getAppList } from "../../api/createProject/index";
import { upgradeNamespace, unusedNamespace } from "@/api/space/index.js";
import TouchUs from "@/components/TouchUs.vue";
import { requireAuthBefore } from "@/static/js/authDecorators";
import { querySearchMainSpace } from "@/api/space/index.js";
import Namespacedialog from './namespacedialog.vue'
export default {
  components: { TouchUs, Namespacedialog },
  data() {
    return {
      namespace: { namespace: '', initPassword: '' },
      showDialog: false,
      appKeyArr: [],
      apps: [],
      appData: {},
      token: '',
      spaceId: '',
      userInfo: ''
    };
  }, methods: {
    @requireAuthBefore()
appClickHandle(app){
  this.appData = app
  let userInfo = localStorage.getItem('editor_userInfo')
  if (userInfo) {
    this.token = JSON.parse(userInfo)?.value.token
  }
  querySearchMainSpace().then((res) => {
    let space = res.data;
    if (res.data) {
      // 升级主站
      this.spaceId = space.id
      if (space.cropLevel === 0) {
        unusedNamespace().then(res => {
          this.namespace.namespace = res.data
          this.showDialog = true
        })

        return false
      }

      let path = app.czyRouter ?? space.tenantDomain
      if (app.czyRouter && app.czyRouter.indexOf('http') !== 0) {
        path = `${space.tenantDomain}?token=${this.token}&path=${path}`
        console.log(path);
      }
      // 跳转主站
      window.open(path, "_blank");
      window.location.reload()
    }
  })

},
close() {
  this.showDialog = false
},
cancel() {
  this.showDialog = false
  this.namespace = { namespace: '', initPassword: '' }
},
sure() {
  if (!this.namespace.namespace) {
    this.$showMessage("e", '请输入主站名称');
    return
  }

  if (!this.namespace.initPassword) {
    this.$showMessage("e", '请输入初始密码');
    return
  }
  upgradeNamespace(this.spaceId, this.namespace).then(res => {
    if (res.code == 200) {
      window.open(`${res.data.tenantDomain}?token=${this.token}`, "_blank");
      window.location.reload()
      querySearchMainSpace().then((res) => {
        if (res.code === 200) {

          this.space = res.data;
        }
      })
      this.cancel()
      return
    }
    this.$showMessage("e", res.message);
  })
},
},
mounted() {
  let arr = [];
  let that = this;
  getAppList().then(res => {
    let data = res.data;
    if (data) {
      data.map(it => {
        if (!arr[it.groupName]) {
          arr[it.groupName] = [];
        }
        arr[it.groupName].push(it);
      });
      that.appKeyArr = Object.keys(arr);
      that.appKeyArr.map(it => {
        that.apps.push(arr[it]);
      });
    }
  });
}
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;

  .card-name {
    display: inline-block;
    width: 180px;
    margin-bottom: 15px;

    div {
      cursor: pointer;
    }
  }

  .title {
    width: 153px;
    display: inline-block;
    color: rgba(255, 69, 0, 1);
    font-size: 16px;
    line-height: 24px;
  }
}


.bg {
  width: 100%;
  margin: 12px 17px;
  // background: #f2f2f7;

  .main-center {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
}

.app-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 10px -10px;

  .temp-item {
    background-color: transparent;
  }

  .app-item {
    width: 285px;
    height: 270px;
    margin: 10px;
    cursor: pointer;

    >img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    div {
      width: 100%;
      height: 100%;
    }
  }
}

.main-content {

  //width: 900px !important;
  .app-group {
    .header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: rgb(69, 68, 68);
      font-weight: 600;
      font-size: 24px;
      padding-top: 20px;
    }

    &:not(:first-child) {
      .header {
        border-top: 1px solid rgba(153, 153, 153, 1);
      }
    }
  }
}

.top {
  margin-top: 20px;
}

.name {
  font-size: 16px;
  color: #fff;
  margin: -40px 0 20px 0;
  width: 100%;
  text-align: left;
}

.closeBtn {
  width: 28px;
  height: 28px;
  float: right;
}


.title1 {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 60px;
  color: #fff;
}

::v-deep .bindClass {
  padding: 0px;
  width: 600px;
  height: 500px;
  background: #48484a;
  opacity: 0.9;
  border-radius: 30px;

  .el-dialog__header {
    display: none;
  }
}

.cc_input {
  width: 470px;
  height: 60px;
  border: 1px solid #aeaeb2;
  border-radius: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;

  .inputDiv {
    flex: 1;
    border: none;
    font-size: 20px;
    line-height: 35px;
    color: #fff;
  }

  .inputDiv::placeholder {
    color: #fff;
    font-weight: 400;
  }
}

.border {
  margin-top: 90px;
  height: 1px;
  width: 100%;
  background: #8e8e93;
}

.sureBtn {
  width: 150px;
  height: 70px;
  line-height: 70px;
  margin-top: 14px;
  text-align: center;
  background: #f57f22;
  border-radius: 35px;
  font-size: 25px;
  color: #ffffff;
  cursor: pointer !important;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  width: 100%;
}

.emptyList {
  margin-top: 27px;
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    margin-top: 7px;
    font-size: 16px;
    color: #95999c;
  }
}

.defultSpaceView {
  cursor: pointer;
  width: 100%;
  padding-top: 50px;
  padding-left: 36px;
  padding-right: 1px;
  padding-bottom: 40px;
  height: auto;
  background-color: #ffffff;
  box-shadow: 0px 9px 50px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  position: relative;

  .spaceView {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .spaceinfo {
      width: 254px;
      margin-right: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .avatarSpace {
        margin-top: 49px;
        width: 88px;
        height: 88px;
        background: #ffffff;
        // border: 1px solid #ffffff;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        object-fit: cover;
      }

      .spaceName {
        text-align: left;
        margin-top: 28px;
        font-size: 22px;
        line-height: 31px;
        color: #000000;
        margin-bottom: 20px;
      }

      .info {
        font-size: 16px;
        line-height: 30px;
        color: #8e8e93;
      }

      .authDiv {
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 6px;
        justify-content: flex-start;
        width: 150px;
        height: 50px;
        background: #e6541b;
        border-radius: 25px;

        img {
          width: 36px;
          height: 36px;
          background: #aeaeb2;
          border: 4px #ffffff solid;
          border-radius: 50%;
          object-fit: cover;
        }

        span {
          font-size: 18px;
          color: #ffffff;
          margin-left: 17px;
        }
      }
    }

    .knowlegelist {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: flex-start;
      overflow-x: hidden;

      .knowlegeItem {
        width: 183px;
        height: 270px;
        display: flex;
        margin-left: 14px;
        flex-direction: column;
        align-items: flex-start;
        background: #d1d1d6;
        border-radius: 20px;

        .knowlege {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 20px;
          background-size: 100% 100% !important;
          background-position: center !important;

          .time {
            font-size: 14px;
            line-height: 20px;
            color: #aeaeb2;
          }
        }
      }
    }

    .moreWorks {
      position: absolute;
      right: -30px;
      top: 40%;
      transform: translateY(-50%);
    }

    .prev {
      width: 54px;
      z-index: 9999;
      position: absolute;
      left: 260px;
      top: 40%;
      transform: translateY(-50%);
    }

    .knowlegeName {
      text-align: left;
      margin-left: 14px;
      margin-top: 10px;
      font-size: 18px;
      color: #48484a;
      width: 180px;
    }
  }
}
</style>
