import request from "@/static/js/request";
import state from "../../store/state";

const base = "/mine";

/*我的模型列表*/
export function knowledgeList(params) {
  params.sourceType = state.sourceType;
  return request({
    url: "/knowledge" + base + "/knowledgeList",
    method: "get",
    params: params,
  });
}

//批量处理 设置为已发布，草稿存档
export function updateKnowlegesStatus(param, status) {
  return request({
    url: "/knowledge/statusBatchChange/" + status,
    method: "post",
    data: param,
  });
}
// 作品批量分类
export function updateKnowlegesSort(data, sortID) {
  return request({
    url: `/knowledge/classificationBatchChange/${sortID}`,
    method: "post",
    data,
  });
}
export function deleteKnowlegesStatus(param) {
  return request({
    url: "/knowledge/deleteBatch",
    method: "post",
    data: param,
  });
}

/*我的浏览模型列表*/
export function browseListWithMine(params) {
  return request({
    url: base + "/recentlyViewed/knowledgeList",
    method: "get",
    params: params,
  });
}

// 我的收藏列表
export function collectionKnowledgeListWithMine(params) {
  return request({
    url: base + "/collection/knowledgeList",
    method: "get",
    params: params,
  });
}
// 我的喜欢列表
export function likeKnowledgeListWithMine(params) {
  return request({
    url: base + "/liked/knowledgeList",
    method: "get",
    params: params,
  });
}

/*删除知识点*/
export function delKnowledge(knowledgeId) {
  return request({
    url: "/knowledge/delete/" + knowledgeId,
    method: "post",
  });
}

/*我的*/
export function queryMineInfo() {
  return request({
    url: base + "/userInfo",
    method: "get",
  });
}

/*我的*/
export function queryMineBaseUserInfo() {
  return request({
    url: base + "/baseUserInfo",
    method: "get",
  });
}

/*修改知识点状态*/
export function publishKnowledge(knowledgeId, publish) {
  let data = { knowledgeId, publish };
  return request({
    url: base + "/publish",
    method: "post",
    qsRequire: true,
    data: data,
  });
}

/*我的关注*/
export function queryFocusUsers(params) {
  return request({
    url: "/focusUsers",
    method: "get",
    params: params,
  });
}
/*通知参数-选中*/
export function notifySettingsParam() {
  return request({
    url: "/notification/mine/settings",
    method: "get",
  });
}

//更新通知的設置
export function updateSettings(data) {
  return request({
    url: "/notification/mine/updateSettings",
    method: "post",
    data: data,
  });
}

/*更新用户信息*/
export function updateUserInfoRequest(data) {
  return request({
    url: base + "/updateUserInfo",
    method: "post",
    data: data,
  });
}

/*更新用户封面信息*/
export function updateHomepage(data) {
  return request({
    url: "/mine/updateHomepage",
    method: "post",
    params: data,
  });
}

/*更新用户头像信息*/
export function updateAvatar(data) {
  return request({
    url: "/mine/updateAvatar",
    method: "post",
    params: data,
  });
}

/*发送手机验证码-修改密码
 * type AUTH_LOGIN ,REGISTER,RESET_PASSWORD
 * */
export function sendPswdCode() {
  let data = {
    dialCode: "86",
    type: "CHANGE_PASSWORD",
  };
  return request({
    url: "/sms/userPhone/send",
    method: "post",
    qsRequire: true,
    data: data,
  });
}

/*修改密码*/
export function updatePswd(psw, code) {
  let data = { psw, code };
  return request({
    url: "/changePsw",
    method: "post",
    qsRequire: true,
    data: data,
  });
}

//获取个人主页粉丝数，关注数量，产品数量，评论等数字统计
export function getRecordCount() {
  return request({
    url: base + "/userRecord",
    method: "get",
  });
}

/*** 最新-我的收藏 */
export function getCollectList(params) {
  return request({
    url: "/mine/collection/knowledgeList",
    method: "get",
    params: params,
  });
}

/** 作品列表 */
export function getWorksList(params) {
  return request({
    url: "/portfolio/list",
    method: "get",
    params: params,
  });
}
/** 保存作品集列表 */
export function saveWorks(params) {
  return request({
    url: "/portfolio/save",
    method: "post",
    data: params,
  });
}
/** 修改作品集列表 */
export function updateWorks(params) {
  return request({
    url: "/portfolio/update",
    method: "post",
    data: params,
  });
}

/** 查看作品集列表详情 */
export function getWorksInfo(params) {
  return request({
    url: "portfolio/info/" + params,
    method: "get",
  });
}
/**作品集列表删除 */
export function delWorks(knowledgeId) {
  return request({
    url: "/portfolio/deleteBatch",
    method: "post",
    data: knowledgeId,
  });
}

/**关闭作品集分享 */
export function closePrivateShare(portfolioId) {
  return request({
    url: "portfolio/pshare/cancel?portfolioId=" + portfolioId,
    method: "get",
  });
}

// 作品集预览
export function getPortfolioInfo(params) {
  return request({
    url: "/user/portfolio/info",
    method: "get",
    params: params,
  });
}

/**作品集列表解密 */
export function verifySecurity(params) {
  return request({
    url: "/pshare/verifySecurity",
    method: "post",
    params: params,
  });
}

/**修改邮箱 */
export function changeEmail(params) {
  return request({
    url: "/account/changeEmail",
    method: "post",
    params: params,
  });
}
/** 修改手机号码 */
export function changePhone(params) {
  return request({
    url: "/account/changePhone",
    method: "post",
    params: params,
  });
}

// 展厅模块 S
export function getTemplateList(params) {
  return request({
    url: "/showroom/templateList",
    method: "get",
    params: params,
  });
}

export function geRoomtList(params) {
  return request({
    url: "/showroom/list",
    method: "get",
    params: params,
  });
}

export function roomtAdd(data) {
  return request({
    url: "/showroom",
    method: "post",
    data: data,
  });
}

export function roomtEdit(data) {
  return request({
    url: "/showroom",
    method: "PUT",
    data: data,
  });
}
export function getRoomtInfo(id) {
  return request({
    url: "/showroom/" + id,
    method: "get",
  });
}
export function delRoom(id) {
  return request({
    url: "/showroom/" + id,
    method: "DELETE",
  });
}

export function geHomeRoomtList(params) {
  return request({
    url: "/showroom/homeList",
    method: "get",
    params: params,
  });
}

/** 通过旧密码修改密码 */
export function changePswWithOld(data) {
  return request({
    url: "/changePswWithOldPsw",
    method: "post",
    data: data,
  });
}

/*** 最新-我的收藏 2024年7月11日17:31:26 */
export function getNewCollectList(params) {
  return request({
    url: "/mine/collection/knowledgeList",
    method: "get",
    params: params,
  });
}

// 百度翻译
export async function translate(q) {
  return await request({
    url: "/util/baidu/translate",
    method: "get",
    params: {
      q: q,
      from: "zh",
      to: "en",
    },
  });
}
