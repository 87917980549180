export default [
  /*底部协议*/
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("@/views/other/agreement/index"),
    children: [
      {
        path: "/SerAgreement",
        name: "SerAgreement",
        component: () => import("@/views/other/agreement/SerAgreement"),
        meta: {
          title: "服务协议",
          keepAlive: true,
          canInMobile: true,
        },
      },

      {
        path: "/cookie",
        name: "cookie",
        component: () => import("@/views/other/agreement/cookie"),
        meta: {
          title: "Cookie政策",
          keepAlive: true,
          canInMobile: true,
        },
      },
      {
        path: "/Complaints",
        name: "Complaints",
        component: () => import("@/views/other/agreement/Complaints"),
        meta: {
          title: "隐私政策",
          keepAlive: true,
          canInMobile: true,
        },
      },
      {
        path: "/PriPolicy",
        name: "PriPolicy",
        component: () => import("@/views/other/agreement/PriPolicy"),
        meta: {
          title: "侵权投诉",
          keepAlive: true,
          canInMobile: true,
        },
      },
      {
        path: "/ContactUs",
        name: "ContactUs",
        component: () => import("@/views/other/agreement/ContactUs"),
        meta: {
          title: "联系我们",
          keepAlive: true,
          canInMobile: true,
        },
      },
    ],
    meta: {
      title: "底部协议",
      keepAlive: true,
    },
  },
];
