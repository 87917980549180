import Vue from "vue";
import VueLazyload from "vue-lazyload";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/zh-CN"; // lang i18n

export function initVue() {
  // 懒加载配置项
  Vue.use(VueLazyload);

  Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: "../assets/defulthead.png",
    loading: "../assets/loading.gif",
    attempt: 1,
  });

  /*事件总线*/
  Vue.prototype.$eventBus = new Vue();
  const $defaultPageListQuery = {
    page: 1,
    pageSize: 20,
  };

  Vue.prototype.$defaultPageListQuery = $defaultPageListQuery;

  Vue.config.productionTip = false;

  /*防止dialog 滚动页面抖动*/
  ElementUI.Dialog.props.lockScroll.default = false;

  ElementUI.Dialog.props.closeOnClickModal.default = false;

  Vue.use(ElementUI, { locale });

  // 滚动到底部加载更多
  Vue.directive("drop-down-loadmore", {
    bind(el, binding) {
      const SELECTWRAP_DOM = el;
      SELECTWRAP_DOM.addEventListener("scroll", function () {
        const condition = SELECTWRAP_DOM.scrollHeight - SELECTWRAP_DOM.scrollTop <= SELECTWRAP_DOM.clientHeight;
        if (condition) {
          binding.value();
        }
      });
    },
  });
}
