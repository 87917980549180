/*【1】手机号：*/

export let isMobileNumber = (rule, value, callback) => {
    if (!value) {
        callback();
    } else {
        const reg = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/;
        const isPhone = reg.test(value);
        value = Number(value); //转换为数字
        if (typeof value === "number" && !isNaN(value)) {//判断是否为数字
            value = value.toString(); //转换成字符串
            if (value.length < 0 || value.length > 12 || !isPhone) { //判断是否为11位手机号
                callback(new Error("手机号码格式如:138xxxx8754"));
            } else {
                callback();
            }
        } else {
            callback(new Error("请输入电话号码"));
        }
    }
};


/*2.座机和传真：*/
export let isTelOrFax = (rule, value, callback) => {
    if (!value) {
        callback();
    } else {
        const reg = /^(\d{3,4}-)?\d{7,8}$/;
        const isTel = reg.test(value);
        if (value.length < 10 || value.length > 13 || !isTel) {//判断传真字符范围是在10到13
            callback(new Error("座机或传真格式如:0376-1234567"));
        } else {
            callback();
        }
    }
};

/*3.邮箱：*/
export let isEmail = (rule, value, callback) => {
    if (!value) {
        callback();
    } else {
        const reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
        const email = reg.test(value);
        if (!email) {
            callback(new Error("邮箱格式如:admin@163.com"));
        } else {
            callback();
        }
    }
};


/*4.身份证号：*/

export let isCardId = (rule, value, callback) => {
    if (!value) {
        return new Error("请输入身份证号)");
    } else {
        const reg = /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[0-2])(([0-2][1-9])|10|20|30|31)\d{3}(\d|X|x)$/;
        const card = reg.test(value);
        if (!card) {
            //判断座机为12位
            callback(new Error("身份证格式如:423024xxxx0216xxxx"));
        } else {
            callback();
        }
    }
};

/*5.价格：*/

export let isPrice = (rule, value, callback) => {
    if (!value) {
        callback();
    } else {
        var reg = /^-?\d{1,4}(?:\.\d{1,2})?$/;
        if (reg.test(value)) {
            callback();
        } else {
            callback(new Error("数字格式:0-9999或小数点后可加1到2位"));//如:1 或1.8 或1.85
        }
    }
};


