<template>
  <div class="lContainer">
    <img class="loading" src="../assets/loading-circle.svg" />
  </div>
</template>

<script>
export default {
  name: "Loading",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.lContainer {
  width: 120px;
  height: 120px;
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // background: url("../../src/assets/loading-round.svg") no-repeat center;
  background-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  animation: loading 1s linear infinite;
}

@media screen and (max-width:$adapterWidth) {
  .lContainer {
    width: 70px;
    // height: 70px;
    z-index: 9999;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // background: url("../../src/assets/loading-round.svg") no-repeat center;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .loading {
    width: 40px;
    height: 40px;
    margin: 0 auto;
    animation: loading 2s linear infinite;
  }
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
