//  订单相关
import request from "@/static/js/request";

// 当前有效账户订单 卡片
export function getCurrentValidOrder() {
  return request({
    url: "/accountOrder/currentValidOrder",
    method: "get",
  });
}

// 取消账户订单
export function cancelAccountOrder(params) {
  return request({
    url: "/accountOrder/cancel",
    method: "put",
    params: params,
  });
}

// 取消增值订单
export function cancelVasOrder(params) {
  return request({
    url: "/vasOrder/cancel",
    method: "put",
    params: params,
  });
}
export function cancelCurrencyOrder(params) {
  return request({
    url: "/currencyOrder/cancel",
    method: "put",
    params: params,
  });
}

// 获取运费
export function getInvoiceConfig() {
  return request({
    url: "/fapiao/config",
    method: "get",
  });
}
// 获取上一次发票信息
export function getLastInvoice() {
  return request({
    url: "/fapiao/latestInfo",
    method: "get",
  });
}

// 新增发票
export function addInvoice(data) {
  return request({
    url: "/fapiao",
    method: "post",
    data: data,
  });
}
// 更新发票
export function updateInvoice(data) {
  return request({
    url: "/fapiao",
    method: "put",
    data: data,
  });
}
// 获取发票信息
export function getInvoiceInfo(id) {
  return request({
    url: "/fapiao/" + id,
    method: "get",
  });
}
// 新增订单
export function accountOrder(data) {
  return request({
    url: "/accountOrder",
    method: "post",
    params: data,
  });
}
// 虚拟币订单
export function currencyOrder(data) {
  return request({
    url: "/currencyOrder",
    method: "post",
    params: data,
  });
}
// 微信支付
export function wxpay(data) {
  return request({
    url: "/wxpay/native",
    method: "post",
    params: data,
  });
}
// 支付宝支付
export function prepay(data) {
  return request({
    url: "/alipay/prepay",
    method: "get",
    params: data,
  });
}

// 支付状态
export function orderStatus(data) {
  return request({
    url: "/wxpay/orderStatus",
    method: "get",
    params: data,
  });
}
// 订单列表
export function getOrderList(data) {
  return request({
    url: "/order/list",
    method: "get",
    params: data,
  });
}
// 增值订单
export function productList(data) {
  return request({
    url: "/vasOrder/productList",
    method: "get",
    params: data,
  });
}
// 新增增值服务订单
export function vasOrder(data) {
  return request({
    url: "/vasOrder",
    method: "post",
    params: data,
  });
}

// 升级租户
export function cropLevels() {
  return request({
    url: "/mine/space/cropLevels",
    method: "get",
  });
}
// 升级企业级别
export function upgradeCropLevel(data) {
  return request({
    url: `/mine/space/upgradeCropLevel/${data.id}`,
    method: "put",
    params: data,
  });
}

// 租户订单
export async function spaceOrder(data) {
  return await request({
    url: `/spaceOrder`,
    method: "post",
    params: { levelType: data },
  });
}
export function cancelSpaceOrder(params) {
  return request({
    url: "/spaceOrder/cancel",
    method: "put",
    params: params,
  });
}
