import Vue from "vue";
import VueRouter from "vue-router";
import { checkIsMobile } from "@/static/utils/utils";
/*路由*/
Vue.use(VueRouter);

//Element/vue router连续点击同一路由报错解决方法
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

let home = [
  /*首页*/
  {
    path: "/",
    name: "index",
    component: () => import("@/views/home"),
    meta: {
      title: "超真云",
      keepAlive: false,
    },
  },
  {
    path: "/smallProgram",
    name: "SmallProgram",
    component: () => import("@/components/wx/index.vue"),
    meta: {
      title: "",
      hiddenNav: true,
      hiddenFooter: true,
      mobileSupported: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/home"),
    meta: {
      title: "超真云",
      keepAlive: false,
    },
  },
  {
    path: "/h4gD8OE",
    name: "h4gD8OE",
    component: () => import("@/views/h4gD8OE/index.vue"),
    meta: {
      title: "学习报名",
      keepAlive: false,
      hiddenNav: true,
      hiddenFooter: true,
    },
  },

  {
    path: "/userShare",
    name: "share",
    component: () => import("@/views/share/index.vue"),
    meta: {
      title: "招募产品体验官",
      requiresAuth: false,
      hiddenNav: true,
      hiddenFooter: true,
      keepAlive: false,
    },
  },
  {
    path: "/baoming",
    name: "share",
    component: () => import("@/views/share/baoming.vue"),
    meta: {
      title: "招募产品体验官",
      requiresAuth: false,
      hiddenNav: true,
      keepAlive: false,
      hiddenFooter: true,
    },
  },

  // 购买页面
  {
    path: "/subscribe",
    name: "subscribe",
    component: () => import("@/views/useEngine/pc/subscribe.vue"),
    meta: {
      title: "订阅",
      keepAlive: false,
      hiddenFooter: true,
    },
  },
  // 支付成功回调页面
  {
    path: "/payCallbackPage",
    name: "PayCallbackPage",
    component: () => import("@/views/useEngine/pc/PayCallbackPage.vue"),
    meta: {
      title: "订阅",
      keepAlive: false,
      hiddenFooter: true,
    },
  },

  {
    path: "/403",
    name: "403",
    component: () => import("@/views/403"),
    meta: {
      title: "403",
    },
  },
  {
    path: "/base",
    name: "BaseInteraction", //全端 3D 交互云平台
    component: () => import("@/views/base/Base"),
    meta: {
      title: "工业智能交互底座",

      hiddenFooter: true,
    },
  },
];

let routers = [
  ...home,
  // ...require("./knowledge").default,
  // ...require("./mine").default,
  // ...require("./message").default,
  // ...require("./user").default,
  ...require("./agreement").default,
  ...require("./commercialization").default,
  ...require("./home").default,
  ...require("./marketing").default,
];
//创建路由
const router = new VueRouter({
  routes: routers,
  mode: "history",
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.VUE_APP_NAME,
});

/**
 * @param {'locationChange' | 'goLogin'} event
 * @param {object?} payload
 * */
export function postMessageInFrame(event, payload = {}) {
  window.top.postMessage(
    {
      source: "czy_message_emit",
      event,
      ...payload,
    },
    "*"
  );
}

//表示首次路由是否解析完成
let isFirstPageLoading = true;
router.onReady(() => {
  isFirstPageLoading = false;
});
export function getFirstPageLoading() {
  return isFirstPageLoading;
}

const inFrame = window.top !== window;

/**
 * 根据 route.meta 里的 canInMobile 和 mobileSupported 控制行为
 * canInMobile      表示该路由页面做了移动端适配，但需要内嵌在移动端通用iframe里面访问以使用移动端页头
 * mobileSupported  表示该路由页面做了移动端适配，且无需内嵌使用
 * @param {import('vue-router').Route} to
 * @param {any} next
 * @return {boolean} 是否需要阻塞路由
 * */
export function hookMobileVisit(to) {
  if (checkIsMobile() && !to.meta.mobileSupported) {
    //移动设备访问主站产生的路由跳转如果有 canInMobile 会强制跳转到移动端对应路由
    //否则跳转到移动端首页
    // console.log(222222222, to);
    // alert(to.fullPath);
    const targetUrl = to.meta.canInMobile ? `${to.fullPath}` : "/";
    //内嵌页面情况下
    if (inFrame) {
      postMessageInFrame("locationChange", {
        url: targetUrl,
      });
      //首次解析完毕后阻塞所有后续路由跳转
      return !isFirstPageLoading;
    } else {
      // location.href = process.env.VUE_APP_WAP_BASE + targetUrl;
    }
  }
}

let relativePath = location.href.slice(location.origin.length).replace(process.env.VUE_APP_NAME, "");
relativePath = relativePath.startsWith("/") ? relativePath : `/${relativePath}`;
hookMobileVisit(router.resolve({ path: relativePath }).route);
export default router;
