<!-- 全局固定右边咨询按钮 -->
<template>
  <section class="app-rigth-btn">
    <div class="box">
      <div @mouseover="handleMouseOver('微信咨询')">
        <svg width="24" height="24" viewBox="0 0 24 24" class="icon" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.5 12H7.51M12 12H12.01M16.5 12H16.51M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 13.1971 3.23374 14.3397 3.65806 15.3845C3.73927 15.5845 3.77988 15.6845 3.798 15.7653C3.81572 15.8443 3.8222 15.9028 3.82221 15.9839C3.82222 16.0667 3.80718 16.1569 3.77711 16.3374L3.18413 19.8952C3.12203 20.2678 3.09098 20.4541 3.14876 20.5888C3.19933 20.7067 3.29328 20.8007 3.41118 20.8512C3.54589 20.909 3.73218 20.878 4.10476 20.8159L7.66265 20.2229C7.84309 20.1928 7.9333 20.1778 8.01613 20.1778C8.09715 20.1778 8.15566 20.1843 8.23472 20.202C8.31554 20.2201 8.41552 20.2607 8.61549 20.3419C9.6603 20.7663 10.8029 21 12 21ZM8 12C8 12.2761 7.77614 12.5 7.5 12.5C7.22386 12.5 7 12.2761 7 12C7 11.7239 7.22386 11.5 7.5 11.5C7.77614 11.5 8 11.7239 8 12ZM12.5 12C12.5 12.2761 12.2761 12.5 12 12.5C11.7239 12.5 11.5 12.2761 11.5 12C11.5 11.7239 11.7239 11.5 12 11.5C12.2761 11.5 12.5 11.7239 12.5 12ZM17 12C17 12.2761 16.7761 12.5 16.5 12.5C16.2239 12.5 16 12.2761 16 12C16 11.7239 16.2239 11.5 16.5 11.5C16.7761 11.5 17 11.7239 17 12Z"
            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div>微信咨询</div>
      </div>
      <div class="wx">
        <img src="@/assets/newImg/0704/qr-wx.png" />
        <div>请用手机微信扫码</div>
      </div>
    </div>
    <div class="box">
      <div @mouseover="handleMouseOver('电话咨询')">
        <svg width="24" height="24" class="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.0497 6C15.0264 6.19057 15.924 6.66826 16.6277 7.37194C17.3314 8.07561 17.8091 8.97326 17.9997 9.95M14.0497 2C16.0789 2.22544 17.9713 3.13417 19.4159 4.57701C20.8606 6.01984 21.7717 7.91101 21.9997 9.94M10.2266 13.8631C9.02506 12.6615 8.07627 11.3028 7.38028 9.85323C7.32041 9.72854 7.29048 9.66619 7.26748 9.5873C7.18576 9.30695 7.24446 8.96269 7.41447 8.72526C7.46231 8.65845 7.51947 8.60129 7.63378 8.48698C7.98338 8.13737 8.15819 7.96257 8.27247 7.78679C8.70347 7.1239 8.70347 6.26932 8.27247 5.60643C8.15819 5.43065 7.98338 5.25585 7.63378 4.90624L7.43891 4.71137C6.90747 4.17993 6.64174 3.91421 6.35636 3.76987C5.7888 3.4828 5.11854 3.4828 4.55098 3.76987C4.2656 3.91421 3.99987 4.17993 3.46843 4.71137L3.3108 4.86901C2.78117 5.39863 2.51636 5.66344 2.31411 6.02348C2.08969 6.42298 1.92833 7.04347 1.9297 7.5017C1.93092 7.91464 2.01103 8.19687 2.17124 8.76131C3.03221 11.7947 4.65668 14.6571 7.04466 17.045C9.43264 19.433 12.295 21.0575 15.3284 21.9185C15.8928 22.0787 16.1751 22.1588 16.588 22.16C17.0462 22.1614 17.6667 22 18.0662 21.7756C18.4263 21.5733 18.6911 21.3085 19.2207 20.7789L19.3783 20.6213C19.9098 20.0898 20.1755 19.8241 20.3198 19.5387C20.6069 18.9712 20.6069 18.3009 20.3198 17.7333C20.1755 17.448 19.9098 17.1822 19.3783 16.6508L19.1835 16.4559C18.8339 16.1063 18.6591 15.9315 18.4833 15.8172C17.8204 15.3862 16.9658 15.3862 16.3029 15.8172C16.1271 15.9315 15.9523 16.1063 15.6027 16.4559C15.4884 16.5702 15.4313 16.6274 15.3644 16.6752C15.127 16.8453 14.7828 16.904 14.5024 16.8222C14.4235 16.7992 14.3612 16.7693 14.2365 16.7094C12.7869 16.0134 11.4282 15.0646 10.2266 13.8631Z"
            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div>电话咨询</div>
      </div>
      <div class="call">
        <div>官方热线</div>
        <div class="tel">400-618-0681</div>
      </div>
    </div>
    <div class="box" @click="consulting">
      <svg width="24" class="icon" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.5 11H12.5M17.5 15H12.5M17.5 7H12.5M9 3L9 21M7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3Z"
          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
      <div>商务咨询</div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    consulting() {
      this.$store.commit("setContactusDialog", true);
      this.handleMouseOver('商务咨询')
    },
    handleMouseOver(name) {
      try {
        TDAPP.onEvent(name.replaceAll(" ", ""));
      } catch (error) {

      }
    }
  },
};
</script>
<style lang="scss" scoped>
.app-rigth-btn {
  position: fixed;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  color: #fff;
  width: 60px;
  height: 200px;
  border-radius: 30px;
  background: rgba(255, 255, 255, 1);
  //box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 17px 17px 0px rgba(0, 0, 0, 0.09);
  padding: 22px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.icon {
  fill: #fff;
  stroke: #888888;
}

.box {
  font-family: PingFang SC;
  font-size: 11px;
  font-weight: 400;
  text-align: center;
  color: rgba(136, 136, 136, 1);
  cursor: pointer;
  position: relative;

  .wx {
    position: absolute;
    right: 70px;
    top: -90px;
    width: 200px;
    height: 200px;
    display: none;
    border-radius: 20px;
    text-align: center;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 13px 13px 0px rgba(0, 0, 0, 0.09);

    img {
      margin: 28px 40px 6px 40px;
      width: 120px;
      height: 120px;


    }

    div {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
      text-align: center;
      color: rgba(37, 37, 37, 1);
    }
  }

  .call {
    position: absolute;
    right: 70px;
    top: -14px;
    width: 200px;
    height: 80px;

    border-radius: 20px;

    box-shadow: 0px 13px 13px 0px rgba(0, 0, 0, 0.09);
    background: rgba(255, 255, 255, 1);
    display: none;

    >div {
      text-align: center;
      color: rgba(0, 0, 0, 1);
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: 400;
      line-height: 30px;
    }

    .tel {
      font-family: Nohemi;
      font-size: 22px;
      font-weight: 400;
      line-height: 30px;
      text-align: center;
      color: rgba(255, 69, 0, 1);
    }

    // opacity: 0px;
  }

  .info {
    width: 200px;
    height: 80px;
    border-radius: 20px;
    display: none;
  }

  &:hover {
    .icon {
      stroke: rgba(255, 69, 0, 1);
    }

    color: rgba(255, 69, 0, 1);

    .call {
      display: inline-block;
    }

    .wx {
      display: inline-block;
    }
  }
}

@media screen and (max-width: 768px) {
  .app-rigth-btn {
    display: none;
  }
}
</style>
