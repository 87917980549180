import { querySearchMainSpace } from "@/api/space/index.js";
import store from "@/store/index.js";
export const appClickHandle = (app) => {
  // 当前应用未发布，弹商务咨询
  if (!app.path) {
    store.commit("setContactusDialog", true);
    return;
  }

  // const tempPage = window.open("", "_blank"); //窗口被拦截后 tempPage 为 null
  if (app.path.indexOf("http") !== -1) {
    window.open(app.path, "_blank");
    return;
  }
  let userInfo = localStorage.getItem("editor_userInfo");
  let token = null;
  if (userInfo) {
    token = JSON.parse(userInfo)?.value.token;
  }
  querySearchMainSpace().then((res) => {
    let space = res.data;
    if (space) {
      let path = app.path ?? space.tenantDomain;
      if (app.path && app.path.indexOf("http") == -1) {
        path = `path=${encodeURIComponent(path)}&token=${token}`;
        let winPath = `${space.tenantDomain}?${path}`;

        window.open(winPath, "_blank");
        return;
      }
    }
  });
};
// 应用编辑跳转
export function goEditApp(app) {
  let userInfo = localStorage.getItem("editor_userInfo");
  let token = null;
  if (userInfo) {
    token = JSON.parse(userInfo)?.value.token;
  }
  querySearchMainSpace().then((res) => {
    console.log(11111);

    let space = res.data;
    if (space) {
      let path = app.path ?? space.tenantDomain;
      if (app.path) {
        path = `&token=${token}`;
        let winPath = `${space.tenantDomain}?${path}`;

        window.open(winPath, "_blank");
        return;
      }
    }
  });
}
