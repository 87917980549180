<template>
  <el-drawer
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :modal="false"
    :show-close="false"
    direction="ltr"
    custom-class="mobile-drawer-menu"
    :before-close="handleDrawerClose"
    size="100%"
  >
    <div class="menu-list">
      <div v-for="(item, index) in menuList" :key="index" class="menu-item" @click="handleDrawerMenuClick(item)">
        {{ item.name }}
      </div>
    </div>
    <div class="line"></div>
    <div v-if="!user" class="login-btn">
      <div @click="handleToRegister">注册</div>
      <div class="vertical-line">|</div>
      <div @click="handleToLogin">登录</div>
    </div>
    <div v-if="user" class="login-out">
      <div class="user-name">{{ user.username }}</div>
      <div class="logout-btn" @click="logout">登出</div>
    </div>
  </el-drawer>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { requireAuthBefore } from "@/static/js/authDecorators";
import { getUserInfo, removeUserInfo } from "@/static/js/userInfo";
import { logout } from "@/api/LoginRegister";
export default {
  name: "MobileMenu",
  data() {
    return {
      menuList: [
        {
          name: "选择超真云",
          path: "/chooseCZY",
          type: "router",
        },
        {
          name: "产品与解决方案",
          path: "/products",
          type: "router",
        },
        {
          name: "应用精选",
          path: "/MobileApps",
          type: "router",
        },
        {
          name: "炽橙新闻",
          path: "/MobileNews",
          type: "router",
        },
        {
          name: "3D 创智大赛",
          path: "/contest/hgd",
          type: "router",
        },
      ],
      visible: false,
      user: getUserInfo(),
    };
  },
  computed: {
    ...mapState(["isMobileMenu", "userInfo"]),
  },
  watch: {
    isMobileMenu(val) {
      if (val) {
        this.visible = true;
        this.user = getUserInfo();
      } else {
        this.visible = false;
      }
    },
    userInfo(v) {
      if (v) {
        this.user = v;
      }
    },
  },
  methods: {
    ...mapMutations(["setIsMobileMenu"]),
    handleDrawerMenuClick(item) {
      if (item.type === "router") {
        this.$router.push(item.path);
        this.setIsMobileMenu(false);
      }
    },
    handleDrawerClose() {
      this.setIsMobileMenu(false);
    },
    logout() {
      logout();
      removeUserInfo();
      this.setIsMobileMenu(false);
      this.$showMessage("s", "退出成功");
    },
    @requireAuthBefore()
    handleToRegister() {},
    @requireAuthBefore()
    handleToLogin() {},
  },
};
</script>

<style lang="scss">
.mobile-drawer-menu {
  .el-drawer__header {
    display: none;
  }

  .el-drawer__body {
    padding: 0;
    background-color: #4e26de;
    padding: 0 31px;
    // padding-top: calc(13px + 50px + 30px);
    padding-top: 93px;

    .login-out {
      .user-name {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        line-height: 48px;
        text-align: left;
        color: #ffffff;
      }

      .logout-btn {
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        line-height: 48px;
        text-align: left;
        color: #ff4500;
      }
    }

    .login-btn {
      display: flex;
      align-items: center;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 600;
      line-height: 48px;
      text-align: left;
      color: #ffffff;

      .vertical-line {
        margin: 0 8px;
      }
    }

    .menu-list {
      .menu-item {
        font-family: PingFang SC;
        font-size: 20px;
        font-weight: 600;
        line-height: 48px;
        text-align: left;
        color: #ffffff;
      }
    }

    .line {
      height: 1px;
      width: 100%;
      background-color: #ffffff;
      margin: 23px 0;
    }
  }
}
</style>
<style lang="scss" scoped></style>
