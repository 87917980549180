import { Message } from "element-ui";
export default function showMessage(type, msg, icon) {
  var screenHeight = document.documentElement.clientHeight;
  if (type == "s") {
    Message.success({
      type: "success",
      message: msg,
      offset: screenHeight / 2,
      customClass: "msgSuccessStyle",
      center: true,
      duration: 2000, //2000, //设置等于0 不会自动消失
    });
  } else if (type == "w") {
    Message.warning({
      message: msg,
      type: "warning",
      offset: screenHeight / 3,
      customClass: "msgWarnningStyle",
      duration: 2000, //设置等于0 不会自动消失
    });
  } else if (type == "e") {
    Message.warning({
      message: msg,
      type: "warning",
      offset: screenHeight / 2,
      customClass: "msgErrorStyle",
      duration: 2000, //设置等于0 不会自动消失
    });
  }
}
